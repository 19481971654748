import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Thx = () => (
  <Layout>
  <SEO title="Merci !" />
  <h1 className="page-header" >Merci pour votre inscription !</h1>
  <p>Vous pouvez également nous suivre sur les réseaux sociaux ou nous contacter par mail</p>
  <p>contact@novmob.com</p>
  <p>A bientôt !</p>
</Layout>
)

export default Thx
